import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useCreateReviewMutation,
  useGetInvestmentDetailsQuery,
} from "../../../../slices/investmentApiSlice";
import Loader from "../../../UI/Loader";
import Message from "../../../UI/Message";
import Rating from "../../../UI/Rating";

const InvestmentDetailsScreen = () => {
  const [createReview, { isLoading: loadingInvestmentReview }] =
    useCreateReviewMutation();

  const { id: investmentId } = useParams();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [targetPercentage, setTargetPercentage] = useState(0);
  const [stoplossPercentage, setStopLossPercentage] = useState("");

  const { userInfo } = useSelector((state) => state.auth);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await createReview({
        investmentId,
        rating,
        comment,
      }).unwrap();
      refetch();
      toast.success("Review created successfully");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const {
    data: investment,
    isLoading,
    error,
    refetch,
  } = useGetInvestmentDetailsQuery(investmentId);

  useEffect(() => {
    if (investment) {
      setTargetPercentage(
        (
          ((investment.target - investment.buyPrice) * 100) /
          investment.buyPrice
        ).toFixed(2)
      );
      setStopLossPercentage(
        (
          ((investment.buyPrice - investment.stopLoss) * 100) /
          investment.buyPrice
        ).toFixed(2)
      );
    }
  }, [investment]);

  return (
    <>
      {isLoading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <Link className="btn btn-light my-3" to="/investments">
            Go Back
          </Link>
          <ListGroup>
            <ListGroup.Item>
              <Row>
                <Col md={4}>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h3>{investment.instrumentName}</h3>
                      <div>at</div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h3>Buy Price : {investment.buyPrice}</h3>
                        </Col>
                      </Row>
                      {"on    "}
                      {investment.boughtAt.substring(0, 10)}
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h3>By: {investment.user.name}</h3>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>

                <Col md={5}>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h3>Horizon :</h3>
                          <div>
                            {investment.horizon
                              ? investment.horizon
                              : "Not available"}
                          </div>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    <ListGroup.Item>
                      <Row>
                        <Col>
                          <h3>Rational : </h3>
                          <h5>{investment.rational}</h5>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>

                <Col md={3}>
                  <Card>
                    <ListGroup variant="flush">
                      {investment.stopLoss ? (
                        <ListGroup.Item>
                          <h3>Stop Loss : {investment.stopLoss}</h3>
                          {stoplossPercentage} % below buy price.
                        </ListGroup.Item>
                      ) : (
                        <ListGroup.Item>
                          <h3>Stop Loss : NA</h3>
                          Stop Loss not applied.
                        </ListGroup.Item>
                      )}

                      {investment.target ? (
                        <ListGroup.Item>
                          <h3>Target : {investment.target}</h3>
                          {targetPercentage} % above buy price.
                        </ListGroup.Item>
                      ) : (
                        <ListGroup.Item>
                          <h3>Target : NA</h3>
                          Target not applied. Check ROI.
                        </ListGroup.Item>
                      )}

                      {investment.target ? (
                        <ListGroup.Item>
                          <h3>ROI : {investment.roi}</h3>
                        </ListGroup.Item>
                      ) : (
                        <ListGroup.Item>
                          <h3>ROI : NA</h3>
                          Retun on Investment not applied. Check target.
                        </ListGroup.Item>
                      )}

                      <ListGroup.Item>
                        {investment.isSold ? (
                          <>
                            {" "}
                            <h3>Sell Price : {investment.sellPrice}</h3>
                            <div>Sold on : {investment.s}</div>
                          </>
                        ) : (
                          <>
                            <h3>Sell Price : NA</h3>
                            <div>May be investment isn't sold yet.</div>
                          </>
                        )}
                      </ListGroup.Item>
                    </ListGroup>
                  </Card>
                </Col>
              </Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Row className="review">
                <Col md={6}>
                  <h2>Reviews</h2>
                  {investment.reviews.length === 0 && (
                    <Message>No Reviews</Message>
                  )}
                  <ListGroup variant="flush">
                    {investment.reviews.map((review) => (
                      <ListGroup.Item key={review._id}>
                        <strong>{review.name}</strong>
                        <Rating value={review.rating} />
                        <p>{review.createdAt.substring(0, 10)}</p>
                        <p>{review.comment}</p>
                      </ListGroup.Item>
                    ))}
                    <ListGroup.Item>
                      <h2>Write a review</h2>

                      {loadingInvestmentReview && <Loader />}

                      {userInfo ? (
                        <Form onSubmit={submitHandler}>
                          <Form.Group className="my-2" controlId="rating">
                            <Form.Label>Rating</Form.Label>
                            <Form.Control
                              as="select"
                              required
                              value={rating}
                              onChange={(e) => setRating(e.target.value)}
                            >
                              <option value="">Select...</option>
                              <option value="1">1 - Poor</option>
                              <option value="2">2 - Fair</option>
                              <option value="3">3 - Good</option>
                              <option value="4">4 - Very Good</option>
                              <option value="5">5 - Excellent</option>
                            </Form.Control>
                          </Form.Group>
                          <Form.Group className="my-2" controlId="comment">
                            <Form.Label>Comment</Form.Label>
                            <Form.Control
                              as="textarea"
                              row="3"
                              required
                              placeholder={
                                "One unchangeable review per investment"
                              }
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                            ></Form.Control>
                          </Form.Group>
                          <Button
                            disabled={loadingInvestmentReview}
                            type="submit"
                            variant="primary"
                          >
                            Submit
                          </Button>
                        </Form>
                      ) : (
                        <Message>
                          Please <Link to="/login">sign in</Link> to write a
                          review
                        </Message>
                      )}
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </>
      )}
    </>
  );
};

export default InvestmentDetailsScreen;
