import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { FaEdit, FaPlus } from "react-icons/fa";
import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../../UI/Loader";
import Message from "../../../UI/Message";
// import InvestmentsList from "./InvestmentsList";
import { toast } from "react-toastify";
import "./Investments.css";

import { useGetMyInvestmentQuery } from "../../../../slices/investmentApiSlice";

const MyInvestmentsListScreen = () => {
  const navigate = useNavigate();
  const {
    data: myInvestments,
    isLoading,
    error,
    refetch,
  } = useGetMyInvestmentQuery();

  const addInvestmentHandler = async () => {
    navigate("/addInvestment");
    try {
    } catch (error) {
      toast.error(error.data.message || error.error);
    }
  };
  return (
    <>
      <Row className="align-items-center">
        <Col>
          <h1>My Investments</h1>
        </Col>
        <Col className="text-end">
          <Button className="my-3" onClick={addInvestmentHandler}>
            <FaPlus /> Add Investment
          </Button>
        </Col>
      </Row>

      {isLoading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <h5>
            Features for filtering by instrument name, date, sold or not etc.
            will be available by Sept end. Thanks!
          </h5>
          <ul className="investments-list">
            {myInvestments.map((myInvestments) => (
              <Card key={myInvestments._id}>
                <Link to={`/investments/${myInvestments._id}`}>
                  <Card.Title as="div" className="product-title">
                    <strong>
                      {myInvestments.instrumentName}
                      <Card.Text style={{ float: "right" }}>
                        {myInvestments.user.name}
                      </Card.Text>
                    </strong>
                  </Card.Title>
                </Link>
                <Card.Body class="d-flex justify-content-between ">
                  <Card.Text>Buy Price :{myInvestments.buyPrice}</Card.Text>
                  <Card.Text>StopLoss :{myInvestments.stopLoss}</Card.Text>
                  <Card.Text>Target :{myInvestments.target}</Card.Text>
                  <Card.Text>ROI :{myInvestments.roi}</Card.Text>
                  <LinkContainer to={`/investments/${myInvestments._id}/edit`}>
                    <Button variant="light" className="btn-sm mx-2">
                      <FaEdit />
                    </Button>
                  </LinkContainer>
                </Card.Body>
                <Card.Title class="d-flex justify-content-between ">
                  <Card.Text>{myInvestments.rational}</Card.Text>
                  <Card.Text>{myInvestments.boughtAt}</Card.Text>
                </Card.Title>
              </Card>
            ))}
          </ul>
        </>
      )}
    </>
  );
};

export default MyInvestmentsListScreen;
