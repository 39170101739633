import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Meta from "../../UI/Meta";
const HomeScreen = () => {
  const style1 = { border: `1px solid black` };
  const style2 = { textAlign: "left" };
  //  const styleTable1 = { border-collapse: `collapse`};
  //  const styleTable2= { margin: `25px 0`};
  //  const styleTable3 = { font-size: `0.9em`};
  //  const styleTabl4 = { font-family: `sans-serif`};
  //  const styleTable5 = { min-width: `400px`};
  //  const styleTable6 = { box-shadow: `0 0 20px rgba(0, 0, 0, 0.15)`};

  return (
    <>
      <Meta></Meta>
      <br />
      <br />
      <h1>Welcome to FinTryst</h1>
      <h5>Making it a little more easy for you!</h5>
      <ul>
        <li style={{ color: "green" }}>
          Tracking what family and friends are investing in at one place. '✓'
        </li>
        <li style={{ color: "orange" }}>
          Maintain privacy in what you want to show and who you want to show
          your investment. Currently its public. Wait for v0.2 Beta
        </li>
        <li style={{ color: "orange" }}>
          No need of maintaining data in excels around investments and trading
          any more. upcoming
        </li>
        <li style={{ color: "orange" }}>
          Updates will be coming. Stay Tuned! Check future updates table for
          more...{" "}
        </li>
      </ul>

      <br />
      <br />
      <h5>
        Lets start with adding your own investments -{">"}{" "}
        <Link to="/investments/myInvestments">
          <Button>My Investments</Button>
        </Link>
      </h5>
      <h5>
        Or watching what people are investing in -{">"}{" "}
        <Link to="/investments">
          <Button>Investments added by all</Button>
        </Link>
      </h5>

      <br />
      <br />
      <br />
      <br />

      <h3>Future updates</h3>
      <h5>
        Current Version: v0.1 Beta (you will be able to control the visibility
        of your own and filter other's visible investments in next release.){" "}
      </h5>
      <table style={{ ...style1 }}>
        <thead style={{ ...style1 }}>
          <tr>
            <td style={{ ...style1 }}>Version</td>
            <td style={{ ...style1 }}>Expected Time Line</td>
            <td style={{ ...style1, ...style2 }}>Objective/Features</td>
          </tr>
        </thead>
        <tbody>
          <tr style={{ ...style1 }}>
            <td style={{ ...style1 }}>v0.1 Beta </td>
            <td style={{ ...style1 }}>Released on 12-11-2023</td>
            <td style={{ ...style1, ...style2 }}>
              {" "}
              Discard the need of excel sheets to keep investments/trades
              related data. <br />
              See your own and your family & friends investments. <br />
            </td>
          </tr>
          <tr style={{ ...style1 }}>
            <td style={{ ...style1 }}>v1 Beta </td>
            <td style={{ ...style1 }}> Before end of Dec-2023</td>
            <td style={{ ...style1, ...style2 }}>
              Filter Investments By user. <br />
              Filter Investments By Instrument Name. <br />
            </td>
          </tr>
          <tr style={{ ...style1 }}>
            <td style={{ ...style1 }}>v0.2 Beta </td>
            <td style={{ ...style1 }}>Jan-2024</td>
            <td style={{ ...style1, ...style2 }}>
              {" "}
              Mark investment public, private or protected.(more control on who
              can watch which of your investments) <br />
              Connect with investors to watch their protected investments.
              (Follow Request) <br />
              filer's on investment's page. <br />
              Excel export of investments. <br />
            </td>
          </tr>
          <tr style={{ ...style1 }}>
            <td style={{ ...style1 }}>v0.3 Beta </td>
            <td style={{ ...style1 }}>Feb-2024</td>
            <td style={{ ...style1, ...style2 }}>
              {" "}
              Expense Tracker. <br></br>
              Home loan emi calculator. <br></br>
              Return on investment table. <br></br>
            </td>
          </tr>
          <tr style={{ ...style1 }}>
            <td style={{ ...style1 }}>v0.4 Beta </td>
            <td style={{ ...style1 }}>2024</td>
            <td style={{ ...style1, ...style2 }}>
              {" "}
              Like, Review, rate friend's investments.<br></br>
              Follow investors to watch their protected investments. <br />
            </td>
          </tr>
          <tr style={{ ...style1 }}>
            <td style={{ ...style1 }}>v0.5 Beta </td>
            <td style={{ ...style1 }}>2024</td>
            <td style={{ ...style1, ...style2 }}>
              Add your trades. <br />
            </td>
          </tr>
          <tr style={{ ...style1 }}>
            <td style={{ ...style1 }}>future beta releases </td>
            <td style={{ ...style1 }}>2024</td>
            <td style={{ ...style1, ...style2 }}>
              Create groups.
              <br />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default HomeScreen;
