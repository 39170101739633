import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  FormGroup,
  Placeholder,
  Row,
  Table,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAddInvestmentMutation } from "../../../../slices/investmentApiSlice";

const AddInvestmentScreen = () => {
  const [addInvestment] = useAddInvestmentMutation();

  //variables
  const [instrumentName, setInstrumentName] = useState("");
  const [category, setCategory] = useState("");
  const [buyOrSell, setBuyOrSell] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [rational, setRational] = useState("");
  const [stopLoss, setStopLoss] = useState("");
  const [roi, setRoi] = useState("");
  const [quantity, setQuantity] = useState("");
  const [buyPrice, setBuyPrice] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [target, setTarget] = useState("");
  const [trailingSL, setTrailingSL] = useState("");
  const [slTriggeredAt, setSlTriggeredAt] = useState("");
  const [tgtTriggeredAt, setTgtTriggeredAt] = useState("");
  const [boughtAt, setBoughtAt] = useState("");
  const [soldAt, setSoldAt] = useState("");
  const [horizon, setHorizon] = useState("");
  const [visibility, setVisibility] = useState("Public");
  const [isSold, setIsSold] = useState(false);

  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      window.confirm(
        "Have you cross checked Buy Price, Instrucment Name and Quantity? You will not be able to edit these later. shall we proceed?"
      )
    ) {
      const addedInvestment = {
        instrumentName,
        category,
        horizon,
        rational,
        stopLoss,
        roi,
        target,
        quantity,
        buyPrice,
        boughtAt,
        sellPrice,
        soldAt,
        isSold,
        visibility,
      };
      console.log(addedInvestment);
      const result = await addInvestment(addedInvestment);
      if (result.error) {
        toast.error(result.error);
      } else {
        toast.success("Investment Added");
        navigate("/investments/myInvestments");
      }
    }
  };

  const handleIsSold = (e) => {
    setIsSold(e.target.checked);
  };

  return (
    <>
      <Link to="/investments/myInvestments" className="btn btn-light my-3">
        Go Back
      </Link>
      <h3>Add New Investment</h3>

      <Form onSubmit={submitHandler}>
        <Row>
          <Col md={3}>
            <Form.Group className="my-2" controlId="visibility">
              <FloatingLabel controlId="floatingSelectGrid" label="Visibility">
                <Form.Control
                  as="select"
                  required
                  value={visibility}
                  onChange={(e) => setVisibility(e.target.value)}
                >
                  <option value="Public">Public</option>
                  <option value="friends">Friends</option>
                  <option value="following">Following</option>
                  <option value="private">private</option>
                  <option value="advisorOnly">Advisor Only</option>
                </Form.Control>
              </FloatingLabel>
            </Form.Group>
          </Col>
        </Row>
        <Table>
          <Row>
            <Col md={6}>
              <fieldset>
                <FormGroup as={Row} controlId="instrumentName">
                  <Col md={3}>
                    <Form.Label column>Instrument Name:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      required
                      onChange={(e) => setInstrumentName(e.target.value)}
                      type="text"
                      value={instrumentName}
                    ></Form.Control>
                  </Col>
                </FormGroup>

                <FormGroup as={Row} controlId="quantity">
                  <Col md={3}>
                    <Form.Label column>Qunatity:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      onChange={(e) => setQuantity(e.target.value)}
                      type="number"
                      value={quantity}
                    ></Form.Control>
                  </Col>
                </FormGroup>
                <FormGroup as={Row} controlId="horizon">
                  <Col md={3}>
                    <Form.Label column>Horizon:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      value={horizon}
                      onChange={(e) => setHorizon(e.target.value)}
                    ></Form.Control>
                  </Col>
                </FormGroup>
                <FormGroup as={Row}>
                  <Col md={3}>
                    <Form.Label column>Bought On:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="date"
                      required
                      value={boughtAt}
                      onChange={(e) => setBoughtAt(e.target.value)}
                    ></Form.Control>
                  </Col>
                </FormGroup>
              </fieldset>
            </Col>

            <Col md={6}>
              <fieldset>
                <FormGroup as={Row} controlId="buyPrice">
                  <Col md={3}>
                    <Form.Label column>Buying Price:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      onChange={(e) => setBuyPrice(e.target.value)}
                      type="number"
                      required
                      value={buyPrice}
                    ></Form.Control>
                  </Col>
                </FormGroup>
                <FormGroup as={Row} controlId="stopLoss">
                  <Col md={3}>
                    <Form.Label column>Stop Loss:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      value={stopLoss}
                      onChange={(e) => setStopLoss(e.target.value)}
                    ></Form.Control>
                  </Col>
                </FormGroup>
                <FormGroup as={Row} controlId="target">
                  <Col md={3}>
                    <Form.Label column>Target:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="number"
                      value={target}
                      onChange={(e) => setTarget(e.target.value)}
                    ></Form.Control>
                  </Col>
                </FormGroup>
                <FormGroup as={Row} controlId="roi">
                  <Col md={3}>
                    <Form.Label column>ROI:</Form.Label>
                  </Col>
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Expected Return on investment"
                      value={roi}
                      onChange={(e) => setRoi(e.target.value)}
                    ></Form.Control>
                  </Col>
                </FormGroup>
              </fieldset>
            </Col>
          </Row>
        </Table>

        <fieldset>
          <Row>
            <Col md={6}>
              <Col md={3}>
                <Form.Label column>Rationale:</Form.Label>
              </Col>
            </Col>
            <Form.Control
              as="textarea"
              value={rational}
              onChange={(e) => setRational(e.target.value)}
            ></Form.Control>
          </Row>
        </fieldset>

        <Placeholder />

        <div>
          <Container>
            <Row>
              <Col md={4}>
                <Form.Check
                  style={{ float: "left" }}
                  reverse
                  sm={{ span: 10, offset: 2 }} // prettier-ignore
                  type={"checkbox"}
                  id={`isSold`}
                  checked={isSold}
                  label={`Sold/Exited from this investment`}
                  onChange={handleIsSold}
                />
              </Col>
            </Row>

            {isSold && (
              <>
                <Row>
                  <FormGroup as={Row} controlId="sellPrice">
                    <Col md={4}>
                      <Form.Label column>Selling Price:</Form.Label>
                      <Form.Control
                        type="number"
                        value={sellPrice}
                        onChange={(e) => setSellPrice(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </FormGroup>
                </Row>

                <Row>
                  <FormGroup as={Row} controlId="soldAt">
                    <Col md={4}>
                      <Form.Label column>Selling Price:</Form.Label>
                      <Form.Control
                        type="date"
                        value={soldAt}
                        onChange={(e) => setSoldAt(e.target.value)}
                      ></Form.Control>
                    </Col>
                  </FormGroup>
                </Row>
              </>
            )}
          </Container>
        </div>

        <Button type="submit" variant="primary" style={{ marginTop: "1rem" }}>
          Add Investment
        </Button>
      </Form>
    </>
  );
};

export default AddInvestmentScreen;
