import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useActivateEmailMutation } from "../../../slices/usersApiSlice";

const ActivateEmailScreen = () => {
  const { token: ftoken } = useParams();
  const [activateEmail, { isLoading, error, refetch }] =
    useActivateEmailMutation();

  const activateEmailHandler = async () => {
    const result = await activateEmail({
      ftoken,
    });
    if (result.error) {
      toast.error(result.error.data.message);
    } else {
      toast.success("Your email account is successfully verified.");
    }
  };

  useEffect(() => {
    activateEmailHandler();
  }, [ftoken]);

  return (
    <>
      {/* {isLoading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant="danger">{result.error.message}</Message>
      ) : (
        <Message variant="success">{result.message}</Message>
      )} */}
    </>
  );
};

export default ActivateEmailScreen;
