import { INVESTMENTS_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const investmentApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInvestments: builder.query({
      query: ({ pageNumber, instrumentName }) => ({
        url: INVESTMENTS_URL,
        params: {
          pageNumber,
          instrumentName,
        },
      }),
      providesTags: ["Investment"],
      keepUnusedDataFor: 5,
    }),
    getInvestmentDetails: builder.query({
      query: (investmentId) => ({
        url: `${INVESTMENTS_URL}/${investmentId}`,
      }),
      keepUnusedDataFor: 5,
    }),
    getInvestmentByUser: builder.query({
      query: (user) => ({
        url: `${INVESTMENTS_URL}/${user}`,
      }),
      providesTags: ["Investment"],
      keepUnusedDataFor: 5,
    }),

    getMyInvestment: builder.query({
      query: () => ({
        url: `${INVESTMENTS_URL}/myInvestments`,
      }),
      providesTags: ["Investment"],
      keepUnusedDataFor: 5,
    }),

    addInvestment: builder.mutation({
      query: (data) => ({
        url: INVESTMENTS_URL,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Investment"],
    }),
    updateInvestment: builder.mutation({
      query: (data) => ({
        url: `${INVESTMENTS_URL}/${data._id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Investment"],
    }),
    createReview: builder.mutation({
      query: (data) => ({
        url: `${INVESTMENTS_URL}/${data.investmentId}/reviews`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Investment"],
    }),
  }),
});

export const {
  useGetInvestmentsQuery,
  useGetInvestmentDetailsQuery,
  useGetInvestmentByUserQuery,
  useAddInvestmentMutation,
  useUpdateInvestmentMutation,
  useGetMyInvestmentQuery,
  useCreateReviewMutation,
} = investmentApiSlice;
