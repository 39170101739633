import { apiSlice } from "./apiSlice";
import { TRADES_URL } from "../constants";

export const tradeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addTrade: builder.mutation({
      query: (trade) => ({
        url: TRADES_URL,
        method: "POST",
        body: trade,
      }),
    }),
    getTradeDetails: builder.query({
      query: (id) => ({
        url: `${TRADES_URL}/${id}`,
      }),
      keepUnusedDataFor: 5,
    }),
    payTrade: builder.mutation({
      query: ({ tradeId, details }) => ({
        url: `${TRADES_URL}/${tradeId}/pay`,
        method: "PUT",
        body: details,
      }),
    }),
    getMyTrades: builder.query({
      query: () => ({
        url: `${TRADES_URL}/mine`,
      }),
      keepUnusedDataFor: 5,
    }),
    getTrades: builder.query({
      query: () => ({
        url: TRADES_URL,
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useAddTradeMutation,
  useGetTradeDetailsQuery,
  usePayTradeMutation,
  useGetMyTradesQuery,
  useGetTradesQuery,
} = tradeApiSlice;
