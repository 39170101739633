import { Helmet } from "react-helmet-async";

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keyword" content={keywords} />
      <link
        rel="icon"
        type="image/png"
        href="../../assets/investment.png"
        sizes="16x16"
      />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "FinTryst",
  description: "For investing with friends and family",
  keywords: "finance, investment, nifty, banknifty, stock",
};

export default Meta;
