import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
// import InvestmentsList from "./InvestmentsList";
import "./Investments.css";
import { useGetInvestmentsQuery } from "../../../../slices/investmentApiSlice";
import Loader from "../../../UI/Loader";
import Message from "../../../UI/Message";
import Rating from "../../../UI/Rating";
import Paginate from "../../../Layout/Paginate";
import SearchBox from "../../../UI/SearchBox";

const InvestmentScreen = () => {
  const { instrumentName, pageNumber } = useParams();
  const { data, isLoading, error } = useGetInvestmentsQuery({
    instrumentName,
    pageNumber,
  });

  // if (investments.length === 0) {
  //   return (
  //     <h2 className="investments-list__fallback">Found no investments.</h2>
  //   );
  // }

  return (
    <>
      {isLoading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <h3 className="mt-3 mb-1">Search Investments</h3>
          <Row>
            <Col md={4}>
              <SearchBox />
            </Col>{" "}
          </Row>

          <h8 className="mb-3">
            Features for filtering by Investor Name, date, sold or not etc. will
            be available soon. Thanks!
          </h8>
          <br />
          {instrumentName && (
            <Link to="/investments" className="btn btn-light mb-3 mt-3">
              Go Back
            </Link>
          )}
          <br />
          <ul className="investments-list">
            {data.investments.map((investment) => (
              <Link
                key={investment._id}
                to={`/investments/${investment._id}`}
                style={{ textDecoration: "none" }}
              >
                <Card key={investment._id} sm={12} md={12}>
                  <Card.Title as="div">
                    <strong>
                      {investment.instrumentName} on{" "}
                      {investment.boughtAt
                        ? investment.boughtAt.substring(0, 10)
                        : ""}{" "}
                      by {investment.user.name}
                      <Card.Text style={{ float: "right" }}>
                        {investment.user.name}
                      </Card.Text>
                    </strong>
                  </Card.Title>

                  <Card.Body class="d-flex justify-content-between ">
                    <Card.Text>Buy Price :{investment.buyPrice}</Card.Text>
                    <Card.Text>
                      StopLoss :
                      {investment.stopLoss ? investment.stopLoss : "NA"}
                    </Card.Text>
                    <Card.Text>
                      Target :{investment.target ? investment.target : "NA"}
                    </Card.Text>
                    <Card.Text>
                      ROI :{investment.roi ? investment.roi : "NA"}
                    </Card.Text>
                  </Card.Body>
                  <Card.Body class="d-flex justify-content-between ">
                    <Card.Text>
                      Rational : {investment.rational}{" "}
                      <Rating
                        value={investment.rating}
                        text={`${investment.numReviews} reviews`}
                      />
                    </Card.Text>
                    {/* <Card.Text>{investment.boughtAt}</Card.Text> */}
                  </Card.Body>
                </Card>
              </Link>
            ))}
          </ul>
          <Paginate pages={data.pages} page={data.page} />
        </>
      )}
    </>
  );
};
//   return (
//     <div>
//       <Card className="investments">
//         {/* <InvestmentsFilter
//               selected={filteredYear}
//               onChangeFilter={filterChangeHandler}
//             />
//             <InvestmentsChart investments={filteredInvestments} /> */}
//         <InvestmentsList items={investments} />
//       </Card>
//     </div>
//   );

export default InvestmentScreen;
