import { useNavigate } from "react-router-dom";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { FaPeopleRoof, FaUser } from "react-icons/fa6";

import { FcBullish, FcPaid } from "react-icons/fc";
import investment from "../../assets/investment.png";
import { LinkContainer } from "react-router-bootstrap";
import { useLogoutMutation } from "../../slices/usersApiSlice";
import { logout } from "../../slices/authSlice";
export const Header = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [logoutApiCall] = useLogoutMutation();

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate("/login");
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
      <Container>
        <LinkContainer to="/">
          <Navbar.Brand>
            <img
              src={investment}
              alt="fintryst"
              width="40"
              height="40"
              className="d-inline-block align-top"
            />
            {"\u00A0"} FinTryst
          </Navbar.Brand>
        </LinkContainer>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {/* <LinkContainer to="/trades">
              <Nav.Link>
                <FcFlashOn />
                Trades
              </Nav.Link>
            </LinkContainer> */}
            {/* <>
              <NavDropdown title="Tryst Point" id="friends">
                <LinkContainer to="/people">
                  <NavDropdown.Item>People</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/friends">
                  <NavDropdown.Item>Friends</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/requests">
                  <NavDropdown.Item>Requests</NavDropdown.Item>
                </LinkContainer>
                <NavDropdown.Item onClick={logoutHandler}>
                  Logout
                </NavDropdown.Item>
              </NavDropdown>
            </> */}
            <LinkContainer to="/people">
              <Nav.Link>
                <FaPeopleRoof size={25} />
                {"\u00A0"} Tryst Point
              </Nav.Link>
            </LinkContainer>
            {/* {"\u00A0"}
            {"\u00A0"} */}
            <LinkContainer to="/investments/page/1">
              <Nav.Link>
                <FcBullish size={25} />
                {"\u00A0"} Investments
              </Nav.Link>
            </LinkContainer>
            {/* <LinkContainer to="/groups">
              <Nav.Link>
                <FaPeopleGroup />
                Groups
              </Nav.Link>
            </LinkContainer> */}
            {/* {"\u00A0"}
            {"\u00A0"} */}
            <LinkContainer to="/investments/myInvestments">
              <Nav.Link>
                <FcPaid size={25} />
                {"\u00A0"} My Investments
              </Nav.Link>
            </LinkContainer>

            {userInfo ? (
              <>
                <NavDropdown title={userInfo.name} id="username">
                  <LinkContainer to="/profile">
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  {/* <LinkContainer to="/myTrades">
                    <NavDropdown.Item>My Trades</NavDropdown.Item>
                  </LinkContainer> */}
                  {/* <LinkContainer to="/investments/myInvestments">
                    <NavDropdown.Item>My Investments</NavDropdown.Item>
                  </LinkContainer> */}
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </>
            ) : (
              <LinkContainer to="/login">
                <Nav.Link>
                  <FaUser /> Sign In
                </Nav.Link>
              </LinkContainer>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
