import React, { useEffect, useState } from "react";
import {
  Table,
  Form,
  Button,
  Row,
  Col,
  Image,
  ListGroup,
  FloatingLabel,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { FaTimes } from "react-icons/fa";

import { toast } from "react-toastify";
import Message from "../../UI/Message";
import Loader from "../../UI/Loader";
import {
  useUpdateProfileDetailsMutation,
  useUpdateProfileMutation,
} from "../../../slices/usersApiSlice";
// import { useGetInvestmentByUserQuery } from "../../../slices/investmentApiSlice";
import { setCredentials } from "../../../slices/authSlice";
import { useUploadProfileImageMutation } from "../../../slices/usersApiSlice";
import { useGetMyProfileQuery } from "../../../slices/usersApiSlice";

const ProfileScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [image, setImage] = useState("");

  const [bio, setBio] = useState("");
  const [profession, setProfession] = useState("");
  const [college, setCollege] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [facebook, setFacebook] = useState("");
  const [youtube, setYoutube] = useState("");
  const [twitter, setTwitter] = useState("");
  const [website, setWebsite] = useState("");

  const { data: user, isLoading, refetch, error } = useGetMyProfileQuery();

  // const { userInfo } = useSelector((state) => state.auth);

  // const { data: investments, isLoading, error } = useGetInvestmentByUserQuery();

  //UpdateProfile is mostly used for updating the password only
  const [updateProfile, { isLoading: loadingUpdateProfile }] =
    useUpdateProfileMutation();

  const [updateProfileDetails, { isLoading: loadingUpdateProfileDetails }] =
    useUpdateProfileDetailsMutation();

  const [uploadProfileImage, { isLoading: loadingUpload }] =
    useUploadProfileImageMutation();

  const uploadFileHandler = async (e) => {
    const formData = new FormData();
    const path = `${user.flingledName}/profile_pictures`;
    formData.append("img", e.target.files[0]);
    formData.append("path", path);

    try {
      const res = await uploadProfileImage(formData).unwrap();
      setImage(res.picture);
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  useEffect(() => {
    console.log(user);
    if (user) {
      setName(user.name);
      setEmail(user.email);
      setImage(user.picture);
      setBio(user.details.bio);
      setProfession(user.details.profession);
      setCollege(user.details.college);
      setCountry(user.details.country);
      setCity(user.details.city);
      setWebsite(user.details.website);
      setInstagram(user.details.instagram);
      setLinkedin(user.details.linkedin);
      setFacebook(user.details.facebook);
      setYoutube(user.details.youtube);
      setTwitter(user.details.twitter);
    }
  }, [user]);

  const dispatch = useDispatch();

  const submitHandler1 = async (e) => {
    console.log("inhandle1");
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
    } else {
      try {
        const res = await updateProfile({
          _id: user._id,
          name,
          email,
          password,
          image,
        }).unwrap();
        dispatch(setCredentials({ ...res }));
        toast.success("Password updated.");
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  const userDetailsSubmitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await updateProfileDetails({
        bio,
        profession,
        college,
        country,
        city,
        instagram,
        website,
        linkedin,
        facebook,
        youtube,
        twitter,
      }).unwrap();
      if (res.error) {
        toast.error(res.error);
      } else {
        toast.success("Your details are Updated");
        refetch();
      }
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return (
    <Row>
      <Col md={3}>
        <h2>User Profile</h2>
        {isLoading ? (
          <Loader></Loader>
        ) : (
          <>
            <Image src={image} alt={user.name} fluid />

            {/* <Form.Group controlId="uploadImage"> */}
            <Form.Group>
              <h3>
                <Form.Label>Browse and upload profile picture</Form.Label>
              </h3>
              <Form.Control
                type="text"
                placeholder="Enter image url"
                value={image}
                onChange={(e) => setImage(e.target.value)}
              ></Form.Control>
              <Form.Control
                label="Choose File"
                onChange={uploadFileHandler}
                type="file"
              ></Form.Control>
              {loadingUpload && <Loader />}
            </Form.Group>

            <br></br>
            <Form onSubmit={submitHandler1}>
              <Form.Group className="my-2" controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="name"
                  readOnly
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="my-2" controlId="email">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  readOnly
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="my-2" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group className="my-2" controlId="confirmPassword">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Button type="submit" variant="primary">
                Update Password
              </Button>

              {loadingUpdateProfile && <Loader />}
            </Form>
          </>
        )}
      </Col>
      <Col md={1}></Col>
      <Col md={8}>
        <h2>User Details</h2>

        <Form onSubmit={userDetailsSubmitHandler}>
          <Row>
            <Form.Label column>About me:</Form.Label>
            <Form.Control
              as="textarea"
              value={bio}
              maxLength="500"
              onChange={(e) => setBio(e.target.value)}
            ></Form.Control>
          </Row>

          {loadingUpdateProfileDetails && <Loader />}

          <Row>
            <Col>
              <Form.Group className="my-2" controlId="profession">
                <Form.Label>Profession</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Enter current & old profession"
                  value={profession}
                  maxLength="100"
                  onChange={(e) => setProfession(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="my-2" controlId="college">
                <Form.Label>Qualification</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Enter your qualifications"
                  value={college}
                  maxLength="100"
                  onChange={(e) => setCollege(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="my-2" controlId="country">
                <Form.Label>Country</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Enter Country handle"
                  value={country}
                  maxLength="100"
                  onChange={(e) => setCountry(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="my-2" controlId="city">
                <Form.Label>City</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Enter City"
                  value={city}
                  maxLength="100"
                  onChange={(e) => setCity(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <br></br>
          <br></br>
          <Row>
            <Col>
              <Form.Group className="my-2" controlId="twitter">
                <Form.Label>Twitter</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Enter Twitter handle"
                  value={twitter}
                  maxLength="100"
                  onChange={(e) => setTwitter(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="my-2" controlId="linkedin">
                <Form.Label>Linkedin</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Enter Linkedin handle"
                  value={linkedin}
                  maxLength="100"
                  onChange={(e) => setLinkedin(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="my-2" controlId="youtube">
                <Form.Label>Youtube</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Enter Youtube handle"
                  maxLength="100"
                  value={youtube}
                  onChange={(e) => setYoutube(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="my-2" controlId="instagram">
                <Form.Label>Instagram</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Enter Instagram handle"
                  value={instagram}
                  maxLength="100"
                  onChange={(e) => setInstagram(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group className="my-2" controlId="facebook">
                <Form.Label>Facebook</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Enter Facebook handle"
                  value={facebook}
                  maxLength="100"
                  onChange={(e) => setFacebook(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="my-2" controlId="website">
                <Form.Label>Website</Form.Label>
                <Form.Control
                  type="input"
                  placeholder="Enter your website"
                  value={website}
                  maxLength="100"
                  onChange={(e) => setWebsite(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col></Col>
            <Col>
              <Button
                style={{ float: "right" }}
                type="submit"
                variant="primary"
              >
                Update My Details
              </Button>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
};

export default ProfileScreen;
