import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
} from "react-bootstrap";
import {
  useGetUserDetailsQuery,
  useCreateProfileReviewMutation,
} from "../../../slices/usersApiSlice";
import { useState } from "react";
import Loader from "../../UI/Loader";
import Message from "../../UI/Message";
import { toast } from "react-toastify";
import {
  FaSquareFacebook,
  FaLinkedin,
  FaSquareYoutube,
  FaSquareInstagram,
  FaSquareTwitter,
  FaGlobe,
} from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import Rating from "../../UI/Rating";

const ProfileDetailsScreen = () => {
  const [createReview, { isLoading: loadingProfileReview }] =
    useCreateProfileReviewMutation();

  const { id: profileId } = useParams();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const { userInfo } = useSelector((state) => state.auth);

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await createReview({
        profileId,
        rating,
        comment,
      }).unwrap();
      refetch();
      toast.success("Review created successfully");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  const {
    data: profile,
    isLoading,
    error,
    refetch,
  } = useGetUserDetailsQuery(profileId);

  useEffect(() => {
    if (profile) {
      // setTargetPercentage(
      //   (
      //     ((profile.target - profile.buyPrice) * 100) /
      //     profile.buyPrice
      //   ).toFixed(2)
      // );
      // setStopLossPercentage(
      //   (
      //     ((profile.buyPrice - profile.stopLoss) * 100) /
      //     profile.buyPrice
      //   ).toFixed(2)
      // );
    }
  }, [profile]);

  return (
    <>
      <Link className="btn btn-light my-3" to="/people">
        Back to community
      </Link>
      {isLoading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          {
            <section class="container">
              <ListGroup>
                <ListGroup.Item>
                  <div class="post bg-white p-1 my-1">
                    <div>
                      <img
                        class="round-img"
                        src={profile.picture}
                        alt={profile.name}
                        fluid
                      />
                      <br></br>
                      <h5>{profile.name}</h5>
                    </div>

                    <div>
                      <p class="my-1">Bio: {profile.details.bio}</p>
                      <p class="my-1">
                        Profession: {profile.details.profession}
                      </p>
                      <p class="my-1">
                        Qualification: {profile.details.college}
                      </p>
                      <p class="my-1">
                        Location:{" "}
                        {profile.details.country &&
                          profile.details.country + ","}{" "}
                        {profile.details.city}
                      </p>
                    </div>
                    <div>
                      <p class="post-date">
                        Joined Fintryst on: {profile.createdAt}
                      </p>
                      <Card.Footer class="d-flex justify-content-between ">
                        <Rating
                          value={profile.rating}
                          text={`${profile.numReviews} reviews`}
                        />
                        {/* <Card.Text>{investment.boughtAt}</Card.Text> */}
                      </Card.Footer>
                      <div class="icons my-1">
                        {profile.details.website && (
                          <a
                            href="profile.details.website"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaGlobe size={40} fill="freeze"></FaGlobe>
                          </a>
                        )}
                        {profile.details.twitter && (
                          <a
                            href={profile.details.twitter}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaSquareTwitter size={40} fill="freeze" />
                          </a>
                        )}
                        {profile.details.facebook && (
                          <a
                            href={profile.details.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaSquareFacebook size={40} fill="freeze" />
                          </a>
                        )}
                        {profile.details.linkedin && (
                          <a
                            href={profile.details.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaLinkedin size={40} fill="freeze" />
                          </a>
                        )}
                        {profile.details.youtube && (
                          <a
                            href={profile.details.youtube}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaSquareYoutube size={40} fill="freeze" />
                          </a>
                        )}
                        {profile.details.instagram && (
                          <a
                            href={profile.details.instagram}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FaSquareInstagram size={40} fill="freeze" />
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </ListGroup.Item>
                <ListGroup.Item>
                  {
                    <Row className="review">
                      <Col md={6}>
                        <h2>Reviews</h2>
                        {profile.reviews.length === 0 && (
                          <Message>No Reviews</Message>
                        )}
                        <ListGroup variant="flush">
                          {profile.reviews.map((review) => (
                            <ListGroup.Item key={review._id}>
                              <strong>{review.name}</strong>
                              <Rating value={review.rating} />
                              <p>{review.createdAt.substring(0, 10)}</p>
                              <p>{review.comment}</p>
                            </ListGroup.Item>
                          ))}
                          <ListGroup.Item>
                            <h2>Write a review</h2>

                            {loadingProfileReview && <Loader />}

                            {userInfo ? (
                              <Form onSubmit={submitHandler}>
                                <Form.Group className="my-2" controlId="rating">
                                  <Form.Label>Rating</Form.Label>
                                  <Form.Control
                                    as="select"
                                    required
                                    value={rating}
                                    onChange={(e) => setRating(e.target.value)}
                                  >
                                    <option value="">Select...</option>
                                    <option value="1">1 - Poor</option>
                                    <option value="2">2 - Fair</option>
                                    <option value="3">3 - Good</option>
                                    <option value="4">4 - Very Good</option>
                                    <option value="5">5 - Excellent</option>
                                  </Form.Control>
                                </Form.Group>
                                <Form.Group
                                  className="my-2"
                                  controlId="comment"
                                >
                                  <Form.Label>Comment</Form.Label>
                                  <Form.Control
                                    as="textarea"
                                    row="3"
                                    required
                                    placeholder={
                                      "One unchangeable review per profile"
                                    }
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                  ></Form.Control>
                                </Form.Group>
                                <Button
                                  disabled={loadingProfileReview}
                                  type="submit"
                                  variant="primary"
                                >
                                  Submit
                                </Button>
                              </Form>
                            ) : (
                              <Message>
                                Please <Link to="/login">sign in</Link> to write
                                a review
                              </Message>
                            )}
                          </ListGroup.Item>
                        </ListGroup>
                      </Col>
                    </Row>
                  }
                </ListGroup.Item>
              </ListGroup>
            </section>
          }
        </>
      )}
    </>
  );
};

export default ProfileDetailsScreen;
