import React, { useState } from "react";
import { Header } from "./components/Layout/Header";
import Footer from "./components/Layout/Footer";
import InvestmentScreen from "./components/features/Investments/Investment/InvestmentScreen";
import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Container } from "react-bootstrap";

const App = () => {
  return (
    <>
      <Header></Header>
      <Container>
        <Outlet></Outlet>
      </Container>
      <Footer></Footer>
      <ToastContainer></ToastContainer>
    </>
  );
};

export default App;
