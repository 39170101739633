import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/index.css";
import "./assets/styles/bootstrap.custom.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";

import reportWebVitals from "./reportWebVitals";
import InvestmentScreen from "./components/features/Investments/Investment/InvestmentScreen";
import InvestmentDetailsScreen from "./components/features/Investments/Investment/InvestmentDetailsScreen";
import LoginScreen from "./components/features/User/LoginScreen";
import RegisterScreen from "./components/features/User/RegisterScreen";
import PrivateRoute from "./components/HelperComponents/PrivateRoute";
import GroupsScreen from "./components/features/Groups/GroupsScreen";
import ProfileScreen from "./components/features/User/MyProfileScreen";
import UserListScreen from "./components/features/User/UserListScreen";
import MyInvestmentsListScreen from "./components/features/Investments/Investment/MyInvestmentsListScreen";
import MyTradesListScreen from "./components/features/Trades/MyTradesListScreen";
import TradeListScreen from "./components/features/Trades/TradeListScreen";
import TradeDetails from "./components/features/Trades/TradeDetails";
import InvestmentEditScreen from "./components/features/Investments/Investment/InvestmentEditScreen";
import AddInvestmentScreen from "./components/features/Investments/Investment/AddInvestmentScreen";
import HomeScreen from "./components/features/Home/HomeScreen";
import ActivateEmailScreen from "./components/features/User/ActivateEmailScreen";
import PeopleScreen from "./components/features/Friends/PeopleScreen";
import ProfileDetailsScreen from "./components/features/Friends/ProfileDetailsScreen";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<HomeScreen />} />
      <Route
        path="/investments/page/:pageNumber"
        element={<InvestmentScreen />}
      />
      <Route
        path="/investments/instrumentName/:instrumentName"
        element={<InvestmentScreen />}
      />
      <Route
        path="/investments/instrumentName/:instrumentName/page/:pageNumber"
        element={<InvestmentScreen />}
      />
      <Route
        path="/investments/page/:pageNumber/instrumentName/:instrumentName"
        element={<InvestmentScreen />}
      />

      <Route path="/investments" element={<InvestmentScreen />} />
      <Route path="/trades" element={<TradeListScreen />} />
      <Route path="/trades/:id" element={<TradeDetails />} />
      <Route path="/activate/:token" element={<ActivateEmailScreen />} />
      <Route path="/investments/:id" element={<InvestmentDetailsScreen />} />
      <Route path="/login" element={<LoginScreen />}></Route>
      <Route path="/register" element={<RegisterScreen />}></Route>
      <Route path="/users" element={<UserListScreen />}></Route>
      <Route path="/groups" element={<GroupsScreen />}></Route>

      <Route path="" element={<PrivateRoute />}>
        <Route
          path="/investments/:id/edit"
          element={<InvestmentEditScreen />}
        />
        <Route path="/addInvestment" element={<AddInvestmentScreen />} />
        <Route path="/shipping" element={<GroupsScreen />} />
        <Route path="/profile" element={<ProfileScreen />} />
        <Route path="/people" element={<PeopleScreen />} />
        <Route path="/people/:id" element={<ProfileDetailsScreen />} />
        <Route
          path="/investments/myInvestments"
          element={<MyInvestmentsListScreen />}
        />
        <Route path="/myTrades" element={<MyTradesListScreen />} />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
