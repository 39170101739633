import { LinkContainer } from "react-router-bootstrap";
import { Table, Button } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import Message from "../../UI/Message";
import Loader from "../../UI/Loader";
import { useGetTradesQuery } from "../../../slices/tradeApiSlice";
import React from "react";
import { Link } from "react-router-dom";
import Card from "react-bootstrap/Card";
// import InvestmentsList from "./InvestmentsList";

const TradeListScreen = () => {
  const { data: trades, isLoading, error } = useGetTradesQuery();
  console.log(trades);

  return (
    <>
      {isLoading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <>
          <ul className="investments-list">
            {trades.map((trade) => (
              <Card>
                <Link to={`/trades/${trade._id}`}>
                  <Card.Title as="div" className="product-title">
                    <strong>
                      {trade.instrumentName} {trade._id}
                    </strong>
                  </Card.Title>
                </Link>
                <Card.Header>
                  {trade.instrumentName} {trade.buyPrice}$
                </Card.Header>
                <Card.Body>
                  <Card.Title>{trade.boughtAt}</Card.Title>
                  <Card.Text>{trade.rational}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </ul>
        </>
      )}
    </>
  );
};

export default TradeListScreen;
