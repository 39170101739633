import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button } from "react-bootstrap";
import { FaTrash, FaEdit, FaCheck, FaTimes } from "react-icons/fa";
import Message from "../../UI/Message";
import Loader from "../../UI/Loader";
import {
  useDeleteUserMutation,
  useGetUsersQuery,
} from "../../../slices/usersApiSlice";
import { toast } from "react-toastify";
import Card from "react-bootstrap/Card";
import "../../../assets/styles/People.css";
import { Link } from "react-router-dom";
import Rating from "../../UI/Rating";

const PeopleScreen = () => {
  const { data: users, refetch, isLoading, error } = useGetUsersQuery();

  const [deleteUser] = useDeleteUserMutation();

  const deleteHandler = async (id) => {
    if (window.confirm("Are you sure")) {
      try {
        await deleteUser(id);
        refetch();
      } catch (err) {
        toast.error(err?.data?.message || err.error);
      }
    }
  };

  return (
    <>
      <h1 class="text-primary">Welcome to the community!</h1>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error?.data?.message || error.error}
        </Message>
      ) : (
        <div>
          <section class="container">
            <p class="lead"> Find investment enthusisasts around...</p>

            <div class="posts">
              {users.map((user) => (
                <Link
                  key={user._id}
                  to={`/people/${user._id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Card key={user._id} sm={12} md={12}>
                    <div class="post bg-white p-1 my-1">
                      <div>
                        <img
                          class="round-img"
                          src={user.picture}
                          alt={user.name}
                          fluid
                        />
                        <br></br>
                        <h5>{user.name}</h5>
                      </div>

                      <div>
                        <p class="my-1">Bio: {user.details.bio}</p>
                        <p class="my-1">
                          Profession: {user.details.profession}
                        </p>
                        <p class="my-1">
                          Qualification: {user.details.college}
                        </p>
                      </div>
                      <div>
                        <p class="post-date">
                          Joined Fintryst on: {user.createdAt}
                        </p>
                        <Card.Footer class="d-flex justify-content-between ">
                          <Rating
                            value={user.rating}
                            text={`${user.numReviews} reviews`}
                          />
                          {/* <Card.Text>{investment.boughtAt}</Card.Text> */}
                        </Card.Footer>
                      </div>
                    </div>
                  </Card>
                </Link>
              ))}
            </div>
          </section>

          {/* {users.map((user) => (
            <Card key={user._id} sm={12} md={12}>
              <Card.Title as="div">
                <Card.Img src={user.picture} alt={user.name} fluid />
                <strong>
                  {user.name}

                  <Card.Text style={{ float: "right" }}>{user.name}</Card.Text>
                </strong>
              </Card.Title> */}

          {/* <Card.Body class="d-flex justify-content-between ">
                <Card.Text>Buy Price :{investment.buyPrice}</Card.Text>
                <Card.Text>
                  StopLoss :{investment.stopLoss ? investment.stopLoss : "NA"}
                </Card.Text>
                <Card.Text>
                  Target :{investment.target ? investment.target : "NA"}
                </Card.Text>
                <Card.Text>
                  ROI :{investment.roi ? investment.roi : "NA"}
                </Card.Text>
              </Card.Body>
              <Card.Body class="d-flex justify-content-between ">
                <Card.Text>
                  Rational : {investment.rational}{" "}
                  <Rating
                    value={user.rating}
                    text={`${user.numReviews} reviews`}
                  />
                </Card.Text>
              </Card.Body> */}
          {/* </Card>
          ))} */}

          {/* <Table striped bordered hover responsive className="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>ADMIN</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user._id}</td>
                  <td>{user.name}</td>
                  <td>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  <td>
                    {!user.isAdmin && (
                      <>
                        <LinkContainer
                          to={`/admin/user/${user._id}/edit`}
                          style={{ marginRight: "10px" }}
                        >
                          <Button variant="light" className="btn-sm">
                            <FaEdit />
                          </Button>
                        </LinkContainer>
                        <Button
                          variant="danger"
                          className="btn-sm"
                          onClick={() => deleteHandler(user._id)}
                        >
                          <FaTrash style={{ color: "white" }} />
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> */}
        </div>
      )}
    </>
  );
};

export default PeopleScreen;
