import { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Placeholder,
  Row,
  Table,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetInvestmentDetailsQuery,
  useUpdateInvestmentMutation,
} from "../../../../slices/investmentApiSlice";
import Loader from "../../../UI/Loader";
import Message from "../../../UI/Message";

const InvestmentEditScreen = () => {
  const [instrumentName, setInstrumentName] = useState("");
  const [category, setCategory] = useState("");
  const [buyOrSell, setBuyOrSell] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [rational, setRational] = useState("");
  const [stopLoss, setStopLoss] = useState("");
  const [roi, setRoi] = useState("");
  const [qunatity, setQunatity] = useState("");
  const [buyPrice, setBuyPrice] = useState("");
  const [sellPrice, setSellPrice] = useState("");
  const [target, setTarget] = useState("");
  const [trailingSL, setTrailingSL] = useState("");
  const [slTriggeredAt, setSlTriggeredAt] = useState("");
  const [tgtTriggeredAt, setTgtTriggeredAt] = useState("");
  const [boughtAt, setBoughtAt] = useState("");
  const [soldAt, setSoldAt] = useState("");
  const [horizon, setHorizon] = useState("");
  const [isSold, setIsSold] = useState(false);

  const navigate = useNavigate();
  const { id: investmentId } = useParams();

  const {
    data: investment,
    isLoading,
    refetch,
    error,
  } = useGetInvestmentDetailsQuery(investmentId);

  const [updateInvestment, { isLoading: LoadingUpdate }] =
    useUpdateInvestmentMutation();

  useEffect(() => {
    if (investment) {
      setInstrumentName(investment.instrumentName);
      setCategory(investment.category);
      setExpiryDate(investment.expiryDate);
      setRational(investment.rational);
      setStopLoss(investment.stopLoss);
      setRoi(investment.roi);
      setQunatity(investment.qunatity);
      setBuyPrice(investment.buyPrice);
      setSellPrice(investment.sellPrice);
      setTarget(investment.target);
      setBoughtAt(investment.boughtAt);
      setSoldAt(investment.soldAt);
      setHorizon(investment.horizon);
      setIsSold(investment.isSold);
      setTrailingSL(investment.trailingSL);
      setSlTriggeredAt(investment.slTriggeredAt);
      setTgtTriggeredAt(investment.tgtTriggeredAt);
      setBuyOrSell(investment.buyOrSell);
    }
  }, [investment]);

  const submitHandler = async (e) => {
    e.preventDefault();
    const updatedInvestment = {
      ...investment,
      rational,
      stopLoss,
      roi,
      sellPrice,
      target,
      horizon,
      soldAt,
      isSold,
    };
    const result = await updateInvestment(updatedInvestment);
    if (result.error) {
      toast.error(result.error);
    } else {
      toast.success("Investment Updated");
      navigate("/myInvestments");
    }
  };

  const handleIsSold = (e) => {
    setIsSold(e.target.checked);
  };

  return (
    <>
      <Link to="/myInvestments" className="btn btn-light my-3">
        Go Back
      </Link>

      {LoadingUpdate && <Loader></Loader>}
      {isLoading ? (
        <Loader></Loader>
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <h3>
            Update {investment.category} investment started on{" "}
            {investment.boughtAt}
          </h3>
          <Form onSubmit={submitHandler}>
            <Table>
              <Row>
                <Col md={6}>
                  <fieldset aria-readonly>
                    <FormGroup as={Row} controlId="instrumentName">
                      <Col md={3}>
                        <Form.Label column>Instrument Name:</Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          readOnly
                          type="text"
                          value={instrumentName}
                        ></Form.Control>
                      </Col>
                    </FormGroup>

                    <FormGroup as={Row} controlId="qunatity">
                      <Col md={3}>
                        <Form.Label column>Qunatity:</Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          readOnly
                          type="number"
                          value={qunatity}
                        ></Form.Control>
                      </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="horizon">
                      <Col md={3}>
                        <Form.Label column>Horizon:</Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          type="text"
                          value={horizon}
                          onChange={(e) => setHorizon(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </FormGroup>
                    <FormGroup as={Row}>
                      <Col md={3}>
                        <Form.Label column>Bought On:</Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          readOnly
                          type="date"
                          value={boughtAt}
                        ></Form.Control>
                      </Col>
                    </FormGroup>
                  </fieldset>
                </Col>

                <Col md={6}>
                  <fieldset>
                    <FormGroup as={Row} controlId="buyPrice">
                      <Col md={3}>
                        <Form.Label column>Buying Price:</Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          readOnly
                          type="number"
                          value={buyPrice}
                        ></Form.Control>
                      </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="stopLoss">
                      <Col md={3}>
                        <Form.Label column>Stop Loss:</Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          type="number"
                          value={stopLoss}
                          onChange={(e) => setStopLoss(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="target">
                      <Col md={3}>
                        <Form.Label column>Target:</Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          type="number"
                          value={target}
                          onChange={(e) => setTarget(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </FormGroup>
                    <FormGroup as={Row} controlId="roi">
                      <Col md={3}>
                        <Form.Label column>Target:</Form.Label>
                      </Col>
                      <Col>
                        <Form.Control
                          type="number"
                          value={roi}
                          onChange={(e) => setRoi(e.target.value)}
                        ></Form.Control>
                      </Col>
                    </FormGroup>
                  </fieldset>
                </Col>
              </Row>
            </Table>

            <fieldset>
              <Row>
                <Col md={6}>
                  <Col md={3}>
                    <Form.Label column>Rational:</Form.Label>
                  </Col>
                </Col>
                <Form.Control
                  as="textarea"
                  value={rational}
                  onChange={(e) => setRational(e.target.value)}
                ></Form.Control>
              </Row>
            </fieldset>

            <Placeholder />

            <div>
              <Container>
                <Row>
                  <Col md={4}>
                    <Form.Check
                      style={{ float: "left" }}
                      reverse
                      sm={{ span: 10, offset: 2 }} // prettier-ignore
                      type={"checkbox"}
                      id={`isSold`}
                      checked={isSold}
                      label={`Sold/Exited from this investment`}
                      onChange={handleIsSold}
                    />
                  </Col>
                </Row>

                {isSold && (
                  <>
                    <Row>
                      <FormGroup as={Row} controlId="sellPrice">
                        <Col md={4}>
                          <Form.Label column>Selling Price:</Form.Label>
                          <Form.Control
                            type="number"
                            value={sellPrice}
                            onChange={(e) => setSellPrice(e.target.value)}
                          ></Form.Control>
                        </Col>
                      </FormGroup>
                    </Row>

                    <Row>
                      <FormGroup as={Row} controlId="soldAt">
                        <Col md={4}>
                          <Form.Label column>Sold On:</Form.Label>
                          <Form.Control
                            type="date"
                            value={soldAt}
                            onChange={(e) => setSoldAt(e.target.value)}
                          ></Form.Control>
                        </Col>
                      </FormGroup>
                    </Row>
                  </>
                )}
              </Container>
            </div>

            <Button
              type="submit"
              variant="primary"
              style={{ marginTop: "1rem" }}
            >
              Update
            </Button>
          </Form>
        </>
      )}
    </>
  );
};

export default InvestmentEditScreen;
